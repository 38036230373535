import { FACEBOOK_SCOPE_PERMISSIONS } from 'constants/defaults';
import { IntegrationType } from 'constants/integrations';
import { useHandleApi } from 'hooks';
import { useParams } from 'react-router-dom';
import { useAdAccountStore } from 'stores';
import { AdAccount, AdAccountStatus } from 'types/stores/ad-account-store';

type SaveAdAccountProps = {
  brandId: string;
  adAccountId: string;
  data: {
    status: AdAccountStatus;
  };
};

export type ArePermissionsGrantedProps = {
  hasAllRequiredPermissions: boolean;
  permissionsList: string[];
};

const useFacebookApi = () => {
  const { loading, handleApi } = useHandleApi();
  const adAccountStore = useAdAccountStore();
  const { brandId: paramBrandId } = useParams();

  const areCustomAudienceTosAccepted = async () => {
    const { metaAdAccount } = adAccountStore;

    if (!metaAdAccount) return undefined;

    const response = await handleApi({
      endpoint: `/brand/${paramBrandId}/fb/adAccount/${metaAdAccount.accountId}/tos`,
      method: 'POST',
    });

    return response.data.acceptedTos as Boolean;
  };

  const arePermissionsGranted = async () => {
    const response = await handleApi({
      endpoint: `/brand/${paramBrandId}/integration/fb/permissions`,
      method: 'POST',
      data: {
        scope: FACEBOOK_SCOPE_PERMISSIONS,
      },
    });

    if (response.error) {
      throw new Error(response.error);
    }

    return response.data as ArePermissionsGrantedProps;
  };

  const refreshAdAccounts = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/fb/adAccount/refresh`, brandId, method: 'POST' });

  const getAdAccounts = async (brandId: string) =>
    handleApi({
      endpoint: `/brand/${brandId}/fb/adAccount`,
      brandId,
      method: 'GET',
    });

  const getActiveAdAccount = async (brandId: string) => {
    const response = await getAdAccounts(brandId);

    // This is for the case if the ad account is in the error state and the user needs to try and re-integrate a different ad account
    if (response.status === 400) {
      adAccountStore.updateAdAccountsList([], IntegrationType.Facebook);

      return undefined;
    }

    if (response.error) {
      throw new Error(response.error);
    }

    adAccountStore.updateAdAccountsList(response.data || [], IntegrationType.Facebook);

    const activeAdAccount = ((response.data as AdAccount[] | '') || []).find(
      (adAccount) => adAccount.status === AdAccountStatus.Active
    );

    return activeAdAccount;
  };

  const saveAdAccount = async ({ brandId, adAccountId, data }: SaveAdAccountProps) =>
    handleApi({
      endpoint: `/brand/${brandId}/fb/adAccount/${adAccountId}`,
      brandId,
      method: 'PATCH',
      data,
    });

  return {
    loading,
    areCustomAudienceTosAccepted,
    arePermissionsGranted,
    getAdAccounts,
    saveAdAccount,
    refreshAdAccounts,
    getActiveAdAccount,
  };
};

export default useFacebookApi;
