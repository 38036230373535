import styled from '@emotion/styled';
import { Button, MetaWhite, Text } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  gap: 8px;
  align-self: stretch;
  background: linear-gradient(90deg, rgba(201, 250, 236, 0.9), rgba(203, 219, 254, 0.9));
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 69px;
  z-index: 4;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 900px;
  text-align: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ResizedButton = styled(Button)`
  padding: 12px;
`;

type MyCreativeMissingMetaPermissionsProps = {
  onConnectMetaAccountClicked: () => void;
};

const MyCreativeMissingMetaPermissions = (props: MyCreativeMissingMetaPermissionsProps) => {
  const { onConnectMetaAccountClicked } = props;

  return (
    <Container>
      <ContentContainer>
        <InnerContainer>
          <Text variant="h2">Looks like you&apos;re missing some permissions!</Text>
          <Text variant="h6" color="neutral70" isTermina={false}>
            Please disconnect and reconnect your Meta account to fetch everything you need. Check
            back in 4 to 6 hours!
          </Text>
        </InnerContainer>
        <InnerContainer>
          <ResizedButton
            onClick={onConnectMetaAccountClicked}
            size="medium"
            startIcon={<MetaWhite />}
            label={<Text variant="body2Semibold">Disconnect Meta</Text>}
          />
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default MyCreativeMissingMetaPermissions;
