import { PropsOf } from '@emotion/react';
import { CellRendererProps, DatePicker, SelectOption } from '@innovationdepartment/proxima-ui';
import { BrandIntegrationStatus } from 'constants/integrations';
import { CreativeAd } from 'types/components/creatives';
import { Brand } from 'types/stores/brand-store';

export enum MyCreativeSortBy {
  HIGHESTSPEND = 'highest_spend',
  LOWESTSPEND = 'lowest_spend',
}

export const sortByOptions: SelectOption[] = [
  {
    label: 'Highest Spend',
    value: MyCreativeSortBy.HIGHESTSPEND,
  },
  {
    label: 'Lowest Spend',
    value: MyCreativeSortBy.LOWESTSPEND,
  },
];

export type MyCreativeViewProps = {
  brand: Brand;
  hasCreativesPermission: boolean;
  creativeBreakdown: MyCreativeBreakdownContainerProps;
  count: number;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  metaStatus: BrandIntegrationStatus | undefined;
  sortBy: string;
  sortByOptions: SelectOption[];
  onSortByChanged: (newSortBy: MyCreativeSortBy) => void;
  onDateRangeSelect: PropsOf<typeof DatePicker>['onDateRangeSelect'];
  onConnectMetaAccountClicked: () => void;
} & MyCreativeTilesProps;

export type MyCreativeTilesProps = {
  loading: boolean;
  ads: CreativeAd[]; // might rename
  onTileClick: (creativeAd: CreativeAd) => void;
  onFetchNext?: () => Promise<void>;
  tilesContainerRef: React.RefObject<HTMLDivElement>;
};

export type MyCreativeTilesTileProps = {
  ad: MyCreativeViewProps['ads'][number];
  thumbnail: string;
  onTileClick: MyCreativeViewProps['onTileClick'];
  tag: { label: string; icon: JSX.Element } | undefined;
};
export type CreativeAdType = {
  Video: 'video';
  Static: 'image';
  Carousel: 'carousel';
};

export type MyCreativeBreakdownRow = {
  creativeType: string;
  percentOfSpend: number;
  roas: any;
  ctr: any;
  cpm: any;
  cpc: any;
  thumbstop: any;
};

export type MyCreativeBreakdownTableProps = { data: MyCreativeBreakdownRow[] };

export type MyCreativeBreakdownTableCellRendererProps<T> = CellRendererProps<T>;

export type MyCreativeBreakdownContainerProps = {
  chartData: {
    totalActiveAds: number;
    video: number;
    static: number;
    carousel: number;
  };
  tableData: MyCreativeBreakdownRow[];
};
